var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-chip",
                {
                  staticClass: "ml-2",
                  attrs: { color: "#435561", small: "", label: "" },
                  on: {
                    click: function ($event) {
                      return _vm.copyText(_vm.text)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v("mdi-content-copy"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showCopiedTooltip,
        callback: function ($$v) {
          _vm.showCopiedTooltip = $$v
        },
        expression: "showCopiedTooltip",
      },
    },
    [_c("span", [_vm._v("Copied")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }