import { render, staticRenderFns } from "./copy-button.vue?vue&type=template&id=403b241f&scoped=true"
import script from "./copy-button.vue?vue&type=script&lang=js"
export * from "./copy-button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403b241f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/wizarre-app-fe/wizarre-app-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('403b241f')) {
      api.createRecord('403b241f', component.options)
    } else {
      api.reload('403b241f', component.options)
    }
    module.hot.accept("./copy-button.vue?vue&type=template&id=403b241f&scoped=true", function () {
      api.rerender('403b241f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/copy-button.vue"
export default component.exports