<template>
    <v-tooltip
        v-model="showCopiedTooltip"
        bottom
    >
        <template v-slot:activator="{ on, attrs }">
            <v-chip
                @click="copyText(text)"
                class="ml-2"
                color="#435561"
                small
                label
            >
                <v-icon small>mdi-content-copy</v-icon>
            </v-chip>
        </template>
        <span>Copied</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        text: String,
    },
    data() {
        return {
            showCopiedTooltip: false
        }
    },
    created() {
    },
    computed: {},
    methods: {
        async copyText(text) {
            await navigator.clipboard.writeText(text);
            this.showCopiedTooltip = true;
            setTimeout(() => this.showCopiedTooltip = false, 2 * 1000);
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
