export class ContestReward {
    /** @type {number} */
    id;
    /** @type {number} */
    amount;
}

export class ContestRewardDefinition {
    /** @type {String} */
    name;
    /** @type {ContestRewardTypes | String} */
    type;
}

export class ContestWinnerStatus {
    /** @type {string} */
    address;
    /** @type {boolean} */
    claimed;
}

export const ContestRewardTypes = Object.freeze({
    WIZARD: 'WIZARD',
    ENHANCEMENT: 'ENHANCEMENT',
    CARD: 'CARD',
    LAND: 'LAND',
})

export const ContestPublishStatuses = Object.freeze({
    NEW: 'NEW',
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    HALTED: 'HALTED',
})
