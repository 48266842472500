import AbstractContract from "../abstractContract";

export class AbstractContestRewardContract extends AbstractContract {
    // @formatter:off
    static contractAddress = null;
    static contractAbi = null;
    // @formatter:on

    constructor(web3, contractAddress) {
        super(web3);
        this.constructor.contractAddress = contractAddress;
    }

    /**
     *
     * @param {String} walletAddress
     * @param {String | Number} contestId
     * @returns {Promise<{exists: boolean, claimed: boolean}>}
     */
    async getClaimStatus(walletAddress, contestId) {
        throw Error('Method not initialized');
    }

    /**
     * @param {String | Number} contestId
     * @returns {Promise<*>}
     */
    async claim(contestId) {
        throw Error('Method not initialized');
    }
}

